import React, { Component } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import AOS from "aos"
import "aos/dist/aos.css"
import NewsStrip from "../components/news-strip"
import MetroFares from "../components/metro-fares"

class Service154Page extends Component {
  componentDidMount() {
    AOS.init()
    window.addEventListener("load", AOS.refresh)
    // @NOTE: Hack to force AOS to work :(
    window.setTimeout(AOS.refresh, 1000)
  }

  componentDidUpdate() {
    AOS.refresh()
  }

  render() {
    return (
      <Layout>
        <SEO
          title="154"
          description="View route information and buy your ticket for the 154 service operated by Tetley's Coaches."
        />
        <div className="block-services inner-body-bg">
          <div className="body gutter page-content-parent">
            <div className="text-center">
              <h1 className="page-content-heading">154 service</h1>
              <p>Haigh Road – Oulton Academy</p>
              <p>Oulton Academy - Pennigton Lane</p>
            </div>
          </div>

          <div className="body gutter page-content-parent">
            <div className="page-content">
              <div className="page-content__text">
                <h2>Route</h2>
                <p style={{ margin: '15px 0'}}>This service will pick up/set down at all regular bus stops along the following route:</p>
                <h3 style={{ margin: '10px 0'}}>AM route</h3>
                <iframe
                  title="154 AM route map"
                  className="route-map"
                  src="https://www.google.com/maps/d/embed?mid=14udNTPcU31WPyprcVGsiIFvdQbfUaYI&ehbc=2E312F"
                  width="100%"
                  height="480"
                ></iframe>
                <p>
                  <strong>Towards</strong> Oulton Academy
                </p>
                <p>
                  From Haigh Road, Styebank Lane, Leeds Road, Holmsley Lane, Church Street, Station Lane, Aberford Road, Leeds Road, Wakefield Road, Pennington Lane, Wakefield Road, Pennington Lane, Royds School.
                </p>
                <h3 style={{ margin: '10px 0'}}>PM route</h3>
                <iframe
                  title="154 PM route map"
                  className="route-map"
                  src="https://www.google.com/maps/d/embed?mid=1kkEY1DV1ewQVZRvASwsmuXuhBwJR3WU&ehbc=2E312F"
                  width="100%"
                  height="480"
                ></iframe>
                <p>
                  <strong>From</strong> Oulton Academy
                </p>
                <p>
                  From Oulton Academy, Pennington Lane, Royds Lane, Marsh Street, Gillett Lane, Park Lane, Styebank Lane, Leeds Road, Holmsley Lane, Church Street, Station Lane, Aberford Road, Leeds Road, Wakefield Road, Pennington Lane.
                </p>
              </div>
              <div className="page-content__text">
                <h2>Timetable</h2>
                <table className="timetable">
                  <thead>
                    <tr>
                      <th colSpan="3">Morning</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Haigh Road</td>
                      <td>DEP</td>
                      <td>0732</td>
                    </tr>
                    <tr>
                      <td>Woodlesford Midland Hotel</td>
                      <td>DEP</td>
                      <td>0739</td>
                    </tr>
                    <tr>
                      <td>Oulton Academy</td>
                      <td>ARR</td>
                      <td>0750</td>
                    </tr>
                  </tbody>
                </table>

                <table className="timetable">
                  <thead>
                    <tr>
                      <th colSpan="3">Afternoon</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Oulton Academy</td>
                      <td>DEP</td>
                      <td>1440</td>
                    </tr>
                    <tr>
                      <td>John O'Gaunts</td>
                      <td>DEP</td>
                      <td>1445</td>
                    </tr>
                    <tr>
                      <td>North Lane</td>
                      <td>DEP</td>
                      <td>1450</td>
                    </tr>
                    <tr>
                      <td>Pennington Lane</td>
                      <td>ARR</td>
                      <td>1500</td>
                    </tr>
                  </tbody>
                </table>

                <MetroFares />
              </div>
            </div>
          </div>
        </div>

        <NewsStrip />
      </Layout>
    )
  }
}

export default Service154Page
